/* 공통 폰트 */

@font-face {
    font-family: "ptd-black";
    src: url("./Pretendard-Black.woff") format("woff");
}

@font-face {
    font-family: "ptd-b";
    src: url("./Pretendard-Bold.woff") format("woff");
}

@font-face {
    font-family: "ptd-eb";
    src: url("./Pretendard-ExtraBold.woff") format("woff");
}

@font-face {
    font-family: "ptd-el";
    src: url("./Pretendard-ExtraLight.woff") format("woff");
}

@font-face {
    font-family: "ptd-l";
    src: url("./Pretendard-Light.woff") format("woff");
}

@font-face {
    font-family: "ptd-m";
    src: url("./Pretendard-Medium.woff") format("woff");
}

@font-face {
    font-family: "ptd-r";
    src: url("./Pretendard-Regular.woff") format("woff");
}

@font-face {
    font-family: "ptd-sb";
    src: url("./Pretendard-SemiBold.woff") format("woff");
}

@font-face {
    font-family: "ptd-t";
    src: url("./Pretendard-Thin.woff") format("woff");
}