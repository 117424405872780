.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.footer-price-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #ffffff;
  border-top: 1px solid #eaeaea;
}

.footer-price-box p {
  margin: 0;
  font-size: 16px;
  color: #333;
}

.footer-price {
  font-weight: bold;
  font-size: 18px;
  color: #00b199;
}

/* 장바구니 및 주문하기 버튼 */
.menu-order-box {
  width: 100%;
}
.menu-order-box > button {
  width: 50%;
  height: 40px;
  border: none;
  color: white;
  font-size: 16px;
}

.cart-btn {
  background-color: #494949;
}

.order-btn {
  background-color: #aff9d6;
}
