* {
  padding: 0;
  margin: 0;
}

.error {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

.error-img {
  width: 300px;
  height: 300px;
  margin: 0 auto;
}

.error-img > img {
  width: 100%;
  height: 100%;
}

.error-msg-box {
  text-align: center;
  margin-top: -30px;
}

.error-msg-code {
  font-size: 100px;
  color: #c5c5c5;
  font-weight: bold;
}

.error-msg {
  font-size: 14px;
  color: #969696;
}
