* {
  margin: 0;
  padding: 0;
}

body {
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  display: none;
}

.menu-item-detail {
  margin-top: 100px;
  padding-bottom: 120px;
}

.menu-item-detail > hr {
  border-top: 1px solid #f1f1f1;
}
.menu-item-detail-image {
  width: 100%;
  height: auto;
}

.menu-name {
  text-align: center;
  margin: 10px;
}

.menu-price {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 30px;
}

/* 옵션 부분 */
.menu-item-detail-options {
  margin: 30px;
}

.menu-item-detail-options > div {
  background-color: #f9fbff;
  margin-top: 10px;
  border-radius: 20px;
  padding: 20px;
}

.menu-item-detail-options > div > p {
  margin-bottom: 10px;
  display: inline;
  font-family: ptd-b;
}

.menu-item-detail-options label {
  margin-right: 20px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.menu-item-detail-options input[type="radio"] {
  appearance: none;
  width: 16px;
  height: 16px;
  border: 1.5px solid #5eb1ff;
  vertical-align: middle;
}
.menu-item-detail-options input[type="radio"]:hover {
  cursor: pointer;
}

.menu-item-detail-options input[type="radio"]:checked {
  background: #5eb1ff no-repeat center center;
  background-image: url("/public/img/check.png");
}
.menu-item-detail-options span {
  margin-left: 10px;
  font-size: 14px;
}

.max-select-message {
  font-size: 13px;
}

/* 수량 조절 */
.menu-quantity {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 15px 30px;
}

.quantity-btn {
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  background-color: #aff9d6;
  color: #ffffff;
}

.quantity {
  display: inline-block;
  width: 20px;
  text-align: center;
  font-size: 16px;
  padding: 5px 10px;
}

/* 총 가격 */
.menu-total-price {
  display: flex;
  justify-content: space-between;
  padding: 15px 30px;
}

.total-Price {
  color: #eb1717;
  font-size: 30px;
  font-family: ptd-b;
}
