.custom-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000080;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.custom-alert {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px #00000033;
}

.custom-alert p {
  margin-bottom: 15px;
}

.custom-alert button {
  width: 100%;
  padding: 5px 10px;
  background-color: #399964;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
