.header {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
  height: 100px;
  width: 100%;
  background-color: #ffffff;
  box-sizing: border-box;
}

.store-logo > img {
  width: 70px;
  height: 70px;
}

.cart-icon-container {
  position: relative;
}

.cart-icon {
  width: 30px;
  height: 30px;
}

.back-icon {
  width: 30px;
  height: 20px;
}

.cart-badge {
  position: absolute;
  top: -5px;
  right: -10px;
  background-color: #00b199;
  color: #ffffff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  font-size: 12px;
  line-height: 20px;
}
