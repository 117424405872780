.payment-result-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  padding: 20px;
}

.payment-result-container h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.payment-result-container p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.result-button {
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.success {
  background-color: #eaffea;
}

.success .result-button {
  background-color: #30e291;
}

.failure {
  background-color: #ffeaea;
}

.failure .result-button {
  background-color: #eb6b6b;
}
