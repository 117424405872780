.cart {
  padding: 20px;
  margin-top: 100px;
  padding-bottom: 60px;
}

.cart-title {
  font-size: 24px;
  font-weight: bold;
  color: #555;
  margin-bottom: 20px;
}

.cart-container {
  margin-bottom: 20px;
  border: 2px solid #aff9d6;
  border-radius: 20px;
  padding: 20px;
}

.cart-item {
  display: flex;
  align-items: center;
  border-radius: 12px;
  padding: 10px;
  margin-bottom: 15px;
}

.cart-item-img {
  width: 60px;
  height: 60px;
  border-radius: 12px;
  object-fit: cover;
  margin-right: 15px;
}

.cart-item-details {
  flex: 1;
}

.cart-item-name {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.cart-item-price {
  font-size: 14px;
  color: #777;
}

.cart-item-remove {
  background: none;
  border: none;
  cursor: pointer;
}

.cart-summary {
  border-top: 1px solid #ddd;
  padding-top: 15px;
}

.cart-tip {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.tip-button {
  background-color: #aff9d6;
  border: none;
  border-radius: 20px;
  padding: 8px 16px;
  font-size: 14px;
  color: #555;
  cursor: pointer;
}

.tip-button.active {
  background-color: #30e291;
  color: #fff;
}

.cart-total {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart-total p {
  font-size: 16px;
  color: #555;
}

.cart-total h3 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.cart-info {
  margin-top: 20px;
}

.cart-info-box {
  margin: 15px 0;
  padding: 10px;
  background-color: #aff9d6;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cart-info-box p {
  margin: 0;
  font-weight: bold;
  color: #006934;
}

.cart-info-box h4 {
  margin: 0;
  font-size: 16px;
  color: #333;
}

.cart-memo {
  width: 100%;
  height: 100px;
  border: none;
  border-radius: 10px;
  padding: 10px;
  background-color: #ffffff;
  resize: none;
  font-size: 14px;
  box-sizing: border-box;
}

.cart-payment {
  margin-top: 20px;
  text-align: center;
}

.payment-buttons {
  display: flex;
  justify-content: space-around;
  margin: 10px 0;
}

.payment-button {
  padding: 10px 20px;
  background-color: #aff9d6;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
}

.payment-button:hover {
  background-color: #30e291;
}

.payment-note {
  font-size: 12px;
  color: #777;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.confirm-btn {
  background-color: #399964;
  color: white;
  padding: 10px 20px;
  margin: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.close-btn {
  background-color: #f44336;
  color: white;
  padding: 10px 20px;
  margin: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
