.main {
  padding: 20px;
  font-family: ptd-r;
  position: relative;
  margin-bottom: 80px;
  margin-top: 100px;
}

.main-top-section {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50px;
  left: 30px;
  right: 50px;
  z-index: 2;
}

.main-logo,
.main-language {
  width: 70px;
  height: 70px;
  cursor: pointer;
  margin-right: 10px;
}

.main-image-section {
  position: relative;
  margin: 20px 0;
}

.main-image {
  width: 100%;
  border-radius: 10px;
  height: 400px;
  object-fit: cover;
  position: relative;
  z-index: 1;
}

.welcome-text {
  position: absolute;
  bottom: 15px;
  left: 10px;
  color: #494949;
  background-color: #ffffff80;
  padding: 5px 30px 5px 30px;
  border-radius: 50px;
  z-index: 2;
}

.welcome-text > p {
  font-size: 25px;
}

.main-continue-section,
.main-table-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  margin-bottom: 15px;
  cursor: pointer;
}

.main-continue-text,
.main-table-text {
  position: absolute;
  z-index: 2;
  bottom: 12px;
  left: 5px;
  background-color: #ffffff80;
  padding: 7px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  gap: 7px;
}

.main-continue-text > img {
  width: 13%;
}

.main-continue-text > span {
  font-size: 13px;
}

.main-continue-bar,
.main-table-bar {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.main-continue-bar > img,
.main-table-bar > img {
  width: 100%;
  max-width: 100%;
  height: auto;
  position: relative;
}

.main-table-icon > img {
  width: 25px;
  height: auto;
}

.main-table-p > p {
  font-size: 13px;
}

.main-options-container {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
}

.main-options-section {
  position: relative;
  display: flex; /* 내부 요소를 플렉스 정렬 */
  flex: 1; /* 각 섹션이 가로 공간을 균등하게 차지 */
  max-width: 200px;
}
.main-option-text {
  display: flex;
  align-items: center;
  gap: 5px;
  position: absolute;
  bottom: 15px;
  left: 5px;
  z-index: 2;
  background-color: #ffffff80;
  border-radius: 50px;
  padding: 5px;
}
.main-option-icon > img {
  width: 33px; /* 아이콘 크기 */
  height: auto;
}

.main-option-p > p {
  font-size: 13px;
}

.main-option-bar > img {
  width: 100%;
  height: auto;
}
