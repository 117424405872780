/* 스크롤바 숨기기 */

.category-scroll-menu::-webkit-scrollbar {
  display: none;
}
.category-scroll-menu {
  display: flex;
  overflow-x: auto;
  margin-bottom: 16px;
  padding: 5px;
  white-space: nowrap;
  position: fixed;
  top: 100px;
  z-index: 10;
  height: 40px;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  background-color: #ffffff;
}

.category-button {
  flex-shrink: 0;
  padding: 8px 16px;
  border: none;
  background-color: #aff9d6;
  border-radius: 20px;
  cursor: pointer;
  margin-right: 8px;
  font-size: 16px;
  color: #727171;
  text-align: center;
}

.category-button.active {
  background-color: #30e291;
  color: white;
  font-weight: bold;
}

.menu-items {
  margin-top: 166px;
  display: flex;
  flex-direction: column;
  padding-bottom: 80px;
}

.menu-category {
  margin-bottom: 20px; /* 카테고리 간격 */
}

.category-title {
  font-size: 20px;
  font-weight: bold;
  color: #006934;
  margin-bottom: 10px;
  padding-left: 20px;
}

.scrollmenu-img {
  width: 100%;
  margin-top: 166px;
}

.scrollmenu-img > img {
  width: 100%;
  height: auto;
  border-radius: 20px;
}

/* MenuItem.css */
.menu-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  margin: 10px 0; /* 카드 간격 */ /* 카드 배경색 */ /* 테두리 색상 */
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.menu-item-img-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-item-img {
  width: 175px;
  height: 150px;
  border-radius: 20px;
  object-fit: cotain;
  border: 2px solid #30e291;
}

.menu-item-details {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #aff9d6;
  border-radius: 20px;
  margin-right: 20px;
  padding: 10px;
}

.menu-item-name {
  font-family: "ptd-b";
  font-size: 15px;
  color: #333333;
  margin-bottom: 5px;
}

.menu-item-desc {
  font-family: "ptd-l";
  font-size: 13px;
  color: #727171;
  padding: 5px;
  margin-bottom: 10px; /* 설명과 가격 간격 */
}

.menu-item-price {
  font-family: "ptd-b";
  font-size: 18px;
  color: #5c5c5c;
}
